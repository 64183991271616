import React, { Fragment, memo, useCallback } from "react";
import xor from "lodash/xor";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Controller, useFormContext } from "react-hook-form";
import useFormFieldError from "./use-form-field-error";

const DefaultContainer = (props) => <Fragment {...props} />;
const DefaultItemContainer = (props) => <Fragment {...props} />;

const FormCheckboxesGroupBody = ({
  label,
  value,
  required,
  error,
  helperText,
  options,
  onChange,
  Container = DefaultContainer,
  ItemContainer = DefaultItemContainer,
}) => {
  return (
    <FormControl
      component="fieldset"
      variant="standard"
      error={error}
      required={required}
      fullWidth
    >
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        <Container>
          {options.map((item) => {
            const checked = value?.includes(item.value) ?? false;
            return (
              <ItemContainer key={item.value}>
                <FormControlLabel
                  label={item.label}
                  name={item.name}
                  value={item.value}
                  onChange={onChange}
                  control={
                    <Checkbox
                      checked={checked}
                      disabled={item.disabled ?? false}
                    />
                  }
                />
              </ItemContainer>
            );
          })}
        </Container>
      </FormGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const MemoizedFormCheckboxesGroupBody = memo(FormCheckboxesGroupBody);

const FormCheckboxesGroup = ({
  name,
  label,
  required,
  options = [],
  Container,
  ItemContainer,
}) => {
  const { setValue, watch } = useFormContext();
  const value = watch(name);
  const { error, helperText } = useFormFieldError(name);
  const handleChange = useCallback(
    (event) => {
      setValue(name, xor(value || [], [event.target.value]), {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [name, setValue, value],
  );

  const render = useCallback(() => {
    return (
      <MemoizedFormCheckboxesGroupBody
        label={label}
        value={value}
        required={required}
        error={error}
        helperText={helperText}
        options={options}
        onChange={handleChange}
        Container={Container}
        ItemContainer={ItemContainer}
      />
    );
  }, [
    Container,
    ItemContainer,
    error,
    handleChange,
    helperText,
    label,
    options,
    required,
    value,
  ]);

  return <Controller name={name} render={render} />;
};

export default FormCheckboxesGroup;
