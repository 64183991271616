import compact from "lodash/compact";
import join from "lodash/join";
import { differenceInMilliseconds } from "date-fns/differenceInMilliseconds";
import keyBy from "lodash/keyBy";
import get from "lodash/get";

export const toName = ({ suffix, firstName, middleName, lastName } = {}) =>
  compact([
    firstName,
    middleName,
    lastName,
    suffix === "none" ? "" : suffix,
  ]).join(" ");

export const toPharmacyName = (pharmacy = {}) =>
  compact([pharmacy?.name, pharmacy?.address, pharmacy?.phone]).join(" ");

export const toAddress = ({ address, city, state, zip } = {}) => {
  if (!address && !city && !state && !zip) {
    return "";
  }
  return `${address || ""} ${city || ""}, ${state || ""} ${zip || ""}`;
};

export const transformEntityToLabels = (items = []) => {
  const valueToLabelMap = {};
  items.forEach((item) => {
    valueToLabelMap[item.value] = item.label;
  });

  valueToLabelMap["no"] = "Denies";
  valueToLabelMap["none"] = "Denies";
  return valueToLabelMap;
};

export const valuesToLabelsList = (values, entityItems = []) => {
  const valueToLabelMap = transformEntityToLabels(entityItems);
  const labels = [];
  values?.forEach((value) => {
    labels.push(valueToLabelMap[value]);
  });
  return join(labels, ", ");
};

export const valueToLabel = (value, options = []) =>
  get(keyBy(options, "value"), [value, "label"]);

export const isDiscardNoteAllowed = (date) => {
  try {
    const inputDate = new Date(date);
    const now = new Date();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    return differenceInMilliseconds(now, inputDate) < oneDayInMilliseconds;
  } catch (e) {
    return false;
  }
};
