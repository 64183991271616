import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";

export const Form = ({
  id,
  mode = "all",
  schema,
  defaultValues,
  onSubmit,
  children,
}) => {
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode,
  });

  return (
    <FormProvider {...methods}>
      <form
        id={id}
        onSubmit={(event) => {
          event.stopPropagation();
          return methods.handleSubmit(onSubmit)(event);
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
};
