import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import MuiDrawer from "@mui/material/Drawer";

import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import Box from "@mui/material/Box";

const Drawer = styled(MuiDrawer)`
  border-right: 1px solid #e6e8eb;
  background-color: transparent;

  > div {
    box-shadow: none;
  }
`;

const Logo = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  box-shadow: none;
  img {
    object-fit: contain;
  }
`;

const Sidebar = ({ items, minSideBar, ...rest }) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <Box
        px={2}
        display="grid"
        gridTemplateRows="auto 1fr auto"
        height="100%"
        bgcolor={minSideBar ? "primary.main" : "white"}
      >
        <Box py={4} mb={2}>
          <Link to="/dashboard">
            <Logo
              alt=""
              src={
                minSideBar
                  ? "/static/img/small-logo.svg"
                  : "/static/img/logo.svg"
              }
            />
          </Link>
        </Box>
        <SidebarNav
          items={items}
          onClose={rest.onClose}
          minSideBar={minSideBar}
        />
        <Footer minSideBar={minSideBar} />
      </Box>
    </Drawer>
  );
};

export default Sidebar;
