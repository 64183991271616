export const FORMIK_COMPONENT_KEYS = {
  INPUT_FIELD: "input-field",
  PHONE_FIELD: "phone-field",
  SELECT: "select",
  RADIO_BUTTON_GROUP: "radio-button-group",
  CHECKBOX_GROUP: "checkbox-group",
  CHECKBOX: "checkbox",
  DATE_PICKER: "date-picker",
  SPACER: "spacer",
  ZIP_CODE: "zip-code",
};
