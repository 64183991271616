import { v1 as uuid } from "uuid";
import parsePhoneNumberFromString from "libphonenumber-js";

export const getUniqPdfId = () => `${uuid()}.pdf`;

export const getShortId = () => uuid().substring(0, 8);

export const parseJson = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return {};
  }
};

export const formatPhoneUsInternational = (value) => {
  try {
    return parsePhoneNumberFromString(value, "US").format("E.164");
  } catch (e) {
    return value;
  }
};

export const formatPhoneUsNational = (value) => {
  try {
    return parsePhoneNumberFromString(value, "US").formatNational();
  } catch (e) {
    return value;
  }
};
