import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import { List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import "../../vendor/perfect-scrollbar.css";
import map from "lodash/map";
import noop from "lodash/noop";
import SidebarNavListItem from "./SidebarNavListItem";
import Box from "@mui/material/Box";

const baseScrollbar = () => css`
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const SidebarNav = ({ items, minSideBar, onClose = noop }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;

  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Box py={2}>
          {map(items, (page) => {
            return (
              <SidebarNavListItem
                key={page.title}
                href={page.href}
                Icon={page.Icon}
                badge={page.badge}
                title={page.title}
                onClose={onClose}
                minSideBar={minSideBar}
              />
            );
          })}
        </Box>
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
