import React, { useCallback } from "react";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import useFormFieldError from "./use-form-field-error";
import noop from "lodash/noop";

const FormRadioButtonsGroup = ({
  name,
  label,
  required,
  options = [],
  onChange = noop,
}) => {
  const { setValue, watch } = useFormContext();
  const value = watch(name);
  const { error, helperText } = useFormFieldError(name);
  const handleChange = useCallback(
    (value) => {
      setValue(name, value, { shouldDirty: true, shouldValidate: true });
      onChange(value);
    },
    [name, onChange, setValue],
  );

  const render = useCallback(
    () => (
      <FormControl required={required} error={error}>
        <FormLabel>{label}</FormLabel>
        <Box display="flex" gap={4} alignItems="flex-start" flexWrap="wrap">
          {options.map((option) => {
            const active = value === option.value;
            return (
              <Button
                key={option.value}
                variant={active ? "contained" : "outlined"}
                color={active ? "primary" : "grey"}
                onClick={() => handleChange(option.value)}
              >
                {option.label}
              </Button>
            );
          })}
        </Box>
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    ),
    [error, helperText, label, handleChange, options, required, value],
  );

  return <Controller name={name} render={render} />;
};

export default FormRadioButtonsGroup;
