import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import { useGetEarnings } from "../../../services/earnings";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { ReactComponent as MainIcon } from "./icons/mail.svg";
import { ReactComponent as MoneyIcon } from "./icons/money.svg";
import Card from "@mui/material/Card";

const SummaryItem = ({ title, earning = 0, encounters = 0, isLoading }) => {
  const value = useMemo(
    () =>
      [encounters, `${encounters === 1 ? "encounter" : "encounters"}`].join(
        " ",
      ),
    [encounters],
  );
  if (isLoading) {
    return <Skeleton variant="rounded" height={120} sx={{ borderRadius: 4 }} />;
  }
  return (
    <Card sx={{ p: 4, borderRadius: 4 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography mb={4} color="grey">
          {title}
        </Typography>
        <MoneyIcon />
      </Box>
      <Typography variant="h2">${earning}</Typography>
      <Box display="flex" alignItems="center" columnGap={1}>
        <MainIcon />
        <Typography color="grey">{value}</Typography>
      </Box>
    </Card>
  );
};

const EarningSummary = () => {
  const { data, isLoading } = useGetEarnings();
  const {
    month = {},
    previousMonth = {},
    year = {},
    allTime = {},
  } = data || {};
  return (
    <Box
      mt={2}
      gap={2}
      display="grid"
      gridTemplateColumns={{
        xs: "1fr",
        sm: "1fr 1fr",
        md: "1fr 1fr 1fr 1fr",
      }}
    >
      <SummaryItem
        isLoading={isLoading}
        title="This months earnings"
        earning={month?.earning}
        encounters={month?.encounters}
      />
      <SummaryItem
        isLoading={isLoading}
        title="Last months earnings"
        earning={previousMonth?.earning}
        encounters={previousMonth?.encounters}
      />
      <SummaryItem
        isLoading={isLoading}
        title="This year"
        earning={year?.earning}
        encounters={year?.encounters}
      />
      <SummaryItem
        isLoading={isLoading}
        title="All time"
        earning={allTime?.earning}
        encounters={allTime?.encounters}
      />
    </Box>
  );
};

export default EarningSummary;
