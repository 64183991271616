import React, { useCallback } from "react";
import styled from "@emotion/styled";
import spacing from "@mui/system/spacing";
import { MenuItem, TextField as MuiTextField } from "@mui/material";

import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import useFormFieldError from "./use-form-field-error";
import { Controller } from "react-hook-form";

const TextField = styled(MuiTextField)(spacing);
const FormSelect = ({ name, label, required, options = [], disabled }) => {
  const { error, helperText } = useFormFieldError(name);
  const render = useCallback(
    ({ field }) => {
      return (
        <FormControl fullWidth>
          <FormLabel>{label}</FormLabel>
          <TextField
            disabled={disabled}
            select
            fullWidth
            variant="outlined"
            name={name}
            required={required}
            error={error}
            helperText={helperText}
            value={field.value}
            onBlur={field.onBlur}
            onChange={field.onChange}
          >
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{ whiteSpace: "normal" }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      );
    },
    [disabled, error, helperText, label, name, options, required],
  );
  return <Controller name={name} render={render} />;
};

export default FormSelect;
