import React, { memo, useCallback, useMemo } from "react";
import styled from "@emotion/styled";
import MuiTextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import usePasswordToggle from "../hooks/use-password-toggle";
import { Controller } from "react-hook-form";
import useFormFieldError from "./use-form-field-error";

const TextField = styled(MuiTextField, {
  shouldForwardProp: (props) => props !== "startAdornment",
})`
  textarea {
    height: auto !important;
  }
`;

const TextInputFieldBody = (props) => {
  const InputProps = useMemo(() => {
    if (!props.multiline) {
      return {
        ...props.InputProps,
        startAdornment: props.startAdornment,
      };
    }
    return {
      ...props.InputProps,
      rows: props.rows,
      multiline: true,
      inputComponent: "textarea",
      startAdornment: props.startAdornment,
    };
  }, [props.InputProps, props.multiline, props.rows, props.startAdornment]);
  return <TextField fullWidth {...props} InputProps={InputProps} />;
};

const MemoizedTextInputFieldBody = memo(TextInputFieldBody);

export const FormTextField = ({
  type: initialType,
  name,
  label,
  placeholder,
  required,
  multiline,
  rows,
  size,
  disabled,
  startAdornment,
}) => {
  const { type, InputProps } = usePasswordToggle(initialType);
  const { error, helperText } = useFormFieldError(name);
  const render = useCallback(
    ({ field }) => {
      const value = field.value === null ? "" : field.value;
      return (
        <FormControl fullWidth>
          <FormLabel>{label}</FormLabel>
          <MemoizedTextInputFieldBody
            type={type}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            required={required}
            value={value}
            error={error}
            helperText={helperText}
            multiline={multiline}
            rows={rows}
            InputProps={InputProps}
            size={size}
            onChange={field.onChange}
            onBlur={field.onBlur}
            startAdornment={startAdornment}
          />
        </FormControl>
      );
    },
    [
      InputProps,
      disabled,
      error,
      helperText,
      label,
      multiline,
      name,
      placeholder,
      required,
      rows,
      size,
      startAdornment,
      type,
    ],
  );

  return <Controller name={name} render={render} />;
};

export default FormTextField;
