import React, { useCallback } from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import useFormFieldError from "./use-form-field-error";
import { Controller } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import noop from "lodash/noop";

const FormCheckbox = ({ name, label, required, onChange = noop }) => {
  const { error, helperText } = useFormFieldError(name);
  const render = useCallback(
    ({ field }) => {
      return (
        <FormControl fullWidth error={error} required={required}>
          <FormControlLabel
            name={name}
            label={label}
            onChange={(event, checked) => {
              field.onChange(event, checked);
              onChange(event, checked);
            }}
            onBlur={field.onBlur}
            control={<Checkbox checked={field.value} />}
          />
          {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      );
    },
    [error, helperText, label, name, onChange, required],
  );

  return <Controller name={name} render={render} />;
};

export default FormCheckbox;
