import React from "react";

import { Button } from "@mui/material";
import { useAuth } from "../../../../contexts/FirebaseAuthContext";
import useSnackBar from "../../../../ui-shared/components/snackbar";
import schema from "./schema";
import Grid from "@mui/material/Grid";
import { FIELDS_CONFIG } from "./config";
import { Form } from "../../../../ui-shared/ui-forms/form";
import { useFormContext } from "react-hook-form";
import FormFieldsRenderer from "../../../../ui-shared/ui-forms/form-fields-renderer";

const FieldWrapper = (props) => {
  return <Grid item xs={12} {...props} />;
};

const Body = () => {
  const { formState } = useFormContext();
  const isSubmitting = formState.isSubmitting;
  return (
    <>
      <Grid container spacing={2}>
        <FormFieldsRenderer
          options={FIELDS_CONFIG}
          FieldWrapper={FieldWrapper}
        />
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        sx={{ my: 4 }}
      >
        Send
      </Button>
    </>
  );
};

function ResetPassword() {
  const { notifySuccess } = useSnackBar();
  const { resetPassword } = useAuth();
  const { notifyError } = useSnackBar();

  return (
    <Form
      schema={schema}
      defaultValues={schema.getDefault()}
      onSubmit={async (values) => {
        try {
          await resetPassword(values.email);
          notifySuccess("Reset password email send successfully");
        } catch (error) {
          const message = error.message || "Something went wrong";
          notifyError(message);
        }
      }}
    >
      <Body />
    </Form>
  );
}

export default ResetPassword;
