import { useAuth } from "../../contexts/FirebaseAuthContext";
import { useMemo } from "react";
import { SYSTEM_ROLE_KEYS } from "../domain-constants/system";

const useRoles = () => {
  const { user = {} } = useAuth();
  const role = user?.role;
  const data = useMemo(() => {
    const isAdmin = role === SYSTEM_ROLE_KEYS.ADMIN;
    const isSupport = role === SYSTEM_ROLE_KEYS.SUPPORT;
    const isDoctor = role === SYSTEM_ROLE_KEYS.DOCTOR;
    return {
      isAdmin,
      isSupport,
      isDoctor,
      isPatient: role === SYSTEM_ROLE_KEYS.PATIENT,
      isProvider: isAdmin || isSupport || isDoctor,
    };
  }, [role]);
  return {
    role,
    ...data,
  };
};

export default useRoles;
