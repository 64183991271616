import React from "react";
import FormTextField from "./fields/form-text-field";
import { FORMIK_COMPONENT_KEYS } from "./constants";
import FormDatePicker from "./fields/form-date-picker";
import FormSelect from "./fields/form-select";
import FormCheckbox from "./fields/form-checkbox";
import FormCheckboxesGroup from "./fields/form-checkbox-group";
import FormRadioButtonsGroup from "./fields/form-radio-buttons-group";
import FormPhoneField from "./fields/form-phone-field";
import ZipCodeField from "./fields/zip-code-field";

const TYPE_TO_COMPONENT_MAP = {
  [FORMIK_COMPONENT_KEYS.INPUT_FIELD]: FormTextField,
  [FORMIK_COMPONENT_KEYS.PHONE_FIELD]: FormPhoneField,
  [FORMIK_COMPONENT_KEYS.DATE_PICKER]: FormDatePicker,
  [FORMIK_COMPONENT_KEYS.SELECT]: FormSelect,
  [FORMIK_COMPONENT_KEYS.CHECKBOX]: FormCheckbox,
  [FORMIK_COMPONENT_KEYS.CHECKBOX_GROUP]: FormCheckboxesGroup,
  [FORMIK_COMPONENT_KEYS.RADIO_BUTTON_GROUP]: FormRadioButtonsGroup,
  [FORMIK_COMPONENT_KEYS.ZIP_CODE]: ZipCodeField,
};

const DefaultFieldWrapper = ({ children }) => {
  return <>{children}</>;
};

const FormFieldsRenderer = ({
  options,
  useCache = false,
  FieldWrapper = DefaultFieldWrapper,
}) => {
  return options.map((item) => {
    if (item.typeKey === FORMIK_COMPONENT_KEYS.SPACER) {
      return <FieldWrapper key={item.name} />;
    }
    const Component = TYPE_TO_COMPONENT_MAP[item.typeKey];
    return (
      <FieldWrapper key={item.name}>
        <Component {...item} useCache={useCache} />
      </FieldWrapper>
    );
  });
};

export default FormFieldsRenderer;
