import React, { forwardRef, memo, useCallback } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Controller, useFormContext } from "react-hook-form";
import useFormFieldError from "./use-form-field-error";
import { IMaskInput } from "react-imask";

const TextMaskCustom = forwardRef((props, ref) => {
  const { onChange, value, ...other } = props;
  return (
    <IMaskInput
      {...other}
      value={value}
      mask="00000[-0000]"
      definitions={{ 0: /[0-9]/ }}
      inputRef={ref}
      onAccept={(value) => {
        onChange({
          target: {
            name: props.name,
            value: value.replace("-", ""),
          },
        });
      }}
      overwrite
    />
  );
});

const TextInputFieldBody = ({ value, onChange, ...rest }) => {
  return (
    <TextField
      type="tel"
      variant="outlined"
      color="primary"
      value={value}
      onChange={onChange}
      {...rest}
      slotProps={{
        input: { inputComponent: TextMaskCustom },
      }}
    />
  );
};

const MemoizedTextInputFieldBody = memo(TextInputFieldBody);

export const ZipCodeField = ({
  name,
  label,
  placeholder,
  required,
  multiline,
  rows,
  size,
  disabled,
}) => {
  const { setValue } = useFormContext();
  const { error, helperText } = useFormFieldError(name);

  const onChange = useCallback(
    (event) => {
      setValue(name, event.target.value, {
        shouldTouch: true,
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [name, setValue],
  );

  const render = useCallback(
    ({ field }) => {
      return (
        <FormControl fullWidth>
          <FormLabel>{label}</FormLabel>
          <MemoizedTextInputFieldBody
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            required={required}
            value={field.value}
            error={error}
            helperText={helperText}
            multiline={multiline}
            rows={rows}
            size={size}
            onChange={onChange}
            onBlur={field.onBlur}
          />
        </FormControl>
      );
    },
    [
      disabled,
      error,
      helperText,
      label,
      multiline,
      name,
      onChange,
      placeholder,
      required,
      rows,
      size,
    ],
  );

  return <Controller name={name} render={render} />;
};

export default ZipCodeField;
