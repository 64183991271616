import { useFormContext } from "react-hook-form";
import get from "lodash/get";
import React, { useMemo } from "react";

const useFormFieldError = (name) => {
  const methods = useFormContext();
  const errorMessage = get(methods, `formState.errors.${name}.message`, "");

  const helperText = useMemo(() => {
    if (!errorMessage) {
      return "";
    }
    return <span dangerouslySetInnerHTML={{ __html: errorMessage }} />;
  }, [errorMessage]);

  return {
    error: Boolean(errorMessage),
    helperText,
  };
};

export default useFormFieldError;
