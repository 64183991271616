import {
  useGetGetWaitingRoomList,
  useInvalidateWaitingRoomList,
} from "../../../services/waiting-room";
import { useEffect } from "react";
import { CHANNELS, pusher } from "../../../di/pusher";
import { sleeper } from "../../../utils/time";
import { Badge } from "@mui/material";
import size from "lodash/size";
import noop from "lodash/noop";

const useEncounterListeners = () => {
  const invalidateWaitingRoomList = useInvalidateWaitingRoomList();
  useEffect(() => {
    const channelKeys = CHANNELS.WAITING_ROOM;
    const channel = pusher.subscribe(channelKeys.key);
    channel.bind(
      channelKeys.EVENTS.ENCOUNTER_ASSIGNED,
      invalidateWaitingRoomList,
    );
    channel.bind(channelKeys.EVENTS.ENCOUNTER_CREATED, function() {
      sleeper(3000).then(invalidateWaitingRoomList).catch(noop);
    });

    return () => {
      channel.unsubscribe();
    };
  }, [invalidateWaitingRoomList]);
};

const WaitingRoomBadge = (props) => {
  useEncounterListeners();
  const { data = [], isLoading } = useGetGetWaitingRoomList();
  if (isLoading) {
    return props.children;
  }
  return <Badge badgeContent={size(data)}   {...props} />;
};

export default WaitingRoomBadge;
