import Pusher from "pusher-js";
import { CONFIG } from "../config";
import { getAccessToken } from "../services/utils";
import axios from "../utils/axios";

export const pusher = new Pusher(CONFIG.PUSHER_KEY, {
  cluster: CONFIG.PUSHER_CLUSTER,
  encrypted: true,
  forceTLS: true,

  channelAuthorization: {
    endpoint: `${CONFIG.API_URL}/pusher/auth`,
    transport: "ajax",
    customHandler: (params, callback) => {
      const body = {
        socket_id: params.socketId,
        channel_name: params.channelName,
      };
      axios
        .post(`${CONFIG.API_URL}/pusher/auth`, body, {
          headers: { Authorization: getAccessToken() },
        })
        .then((response) => {
          if (response.data && response.data.auth) {
            callback(null, response.data);
          } else {
            callback(new Error("Authorization failed"), null);
          }
        })
        .catch((error) => {
          console.error("Error during custom handler authorization:");
          callback(error, null);
        });
    },
  },
});

// Pusher.logToConsole = true;

export const CHANNELS = {
  WAITING_ROOM: {
    key: "private-waiting-room",
    EVENTS: {
      ENCOUNTER_ASSIGNED: "encounter-assigned",
      ENCOUNTER_CREATED: "encounter-created",
    },
  },
};
