import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { ListItemButton, ListItemText } from "@mui/material";

const MinItem = styled(ListItemButton)`
  color: white;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  padding: 2px 4px;

  svg {
    color: white;
    font-size: 30px;
    width: 30px;
    height: 30px;
    padding: 4px;
    border-radius: 4px;
  }

  &:hover {
    background: #ffffff29;
  }

  span.MuiBadge-badge {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
  &.active {
    background: #ffffff29;

    span.MuiBadge-badge {
      color: white;
      background-color: ${(props) => props.theme.palette.secondary.main};
    }
  }
`;

const Item = styled(ListItemButton)`
  padding: 12px;
  color: black;
  border-radius: 8px;
  margin-bottom: 4px;

  svg {
    color: black;
    font-size: 20px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: #fef1ed;
  }

  span.MuiBadge-badge {
    color: white;
    background-color: ${(props) => props.theme.palette.primary.main};
  }

  &.active {
    background-color: ${(props) => props.theme.palette.primary.main};

    svg,
    span {
      color: white;
    }

    span.MuiBadge-badge {
      color: white;
      background-color: ${(props) => props.theme.palette.secondary.main};
    }
  }
`;

const Title = styled(ListItemText)`
  margin: 0;

  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MinTitle = styled(ListItemText)`
  width: 100%;
  span {
    text-align: center;
    font-size: smaller;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  }
`;

const SidebarNavListItem = (props) => {
  const { title, href, depth = 0, Icon, onClose, minSideBar } = props;

  const Component = minSideBar ? MinItem : Item;
  const TitleComponent = minSideBar ? MinTitle : Title;
  return (
    <Component
      depth={depth}
      component={NavLink}
      to={href}
      activeclassname="active"
      onClick={onClose}
    >
      {props.children}
      {Icon}
      <TitleComponent>{title}</TitleComponent>
    </Component>
  );
};

export default SidebarNavListItem;
