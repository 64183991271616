import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectStorageEmulator, getStorage } from "firebase/storage";

import { CONFIG, firebaseConfig } from "../config";

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

if (CONFIG.isEmulator) {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099/");
  connectStorageEmulator(storage, "localhost", 9199);
}

const STORAGE_KEYS = {
  ENCOUNTER_DOCUMENTS: "encounter-documents",
  USER_DOCUMENTS: "user-documents",
};

export { db, auth, storage, STORAGE_KEYS };
