import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apis from "../apis";
import useSnackBar from "../ui-shared/components/snackbar";
import { getApiErrorMessage } from "../utils/error-message-transform";
import { useCallback } from "react";
import noop from "lodash/noop";
import { WAITING_ROOM_CACHE_KEYS } from "./cache-keys";

export const useGetGetWaitingRoomList = () => {
  return useQuery({
    queryKey: [WAITING_ROOM_CACHE_KEYS.LIST],
    queryFn: () =>
      apis.waitingRoom.list().then((response) => response?.data?.data || {}),
    refetchOnWindowFocus: false,
  });
};

export const useInvalidateWaitingRoomList = () => {
  const queryClient = useQueryClient();
  return useCallback(
    () =>
      queryClient
        .invalidateQueries({ queryKey: [WAITING_ROOM_CACHE_KEYS.LIST] })
        .catch(noop),
    [queryClient],
  );
};

export const useAssignEncounter = () => {
  const invalidateWaitingRoomList = useInvalidateWaitingRoomList();
  const { notifySuccess, notifyError } = useSnackBar();
  return useMutation({
    mutationFn: (data) =>
      apis.waitingRoom
        .assignEncounter(data)
        .then((response) => response?.data?.data || {}),
    onSuccess: async () => {
      await invalidateWaitingRoomList();
      notifySuccess("Patient assigned successfully");
    },
    onError: (error) => {
      notifyError(getApiErrorMessage(error));
    },
  });
};
