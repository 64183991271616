import React, { useCallback } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import useFormFieldError from "./use-form-field-error";
import { Controller } from "react-hook-form";
import { checkIsValidDateString } from "../../../utils/time";

const FormDatePicker = ({ name, label, required }) => {
  const { error, helperText } = useFormFieldError(name);
  const render = useCallback(
    ({ field }) => {
      const slotProps = {
        textField: {
          required,
          helperText,
          error,
        },
      };
      const value = checkIsValidDateString(field.value)
        ? new Date(field.value)
        : null;
      return (
        <FormControl fullWidth>
          <FormLabel>{label}</FormLabel>
          <DatePicker {...field} value={value} slotProps={slotProps} />
        </FormControl>
      );
    },
    [error, helperText, label, required],
  );

  return <Controller name={name} render={render} />;
};

export default FormDatePicker;
