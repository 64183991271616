export const SYSTEM_ROLE_KEYS = {
  PATIENT: "Patient",
  DOCTOR: "Doctor",
  SUPPORT: "Support",
  ADMIN: "Admin",
};

export const CHAT_ROLE_KEYS = {
  PATIENT: "user",
  DOCTOR: "moderator",
  SUPPORT: "admin",
  ADMIN: "admin",
};

export const APPOINTMENT_STATUS_KEYS = {
  ALL: "all",
  UNASSIGNED: 0,
  ASSIGNED: 1,
  CLOSED: 2,
  REOPENED: 3,
  REFUND: 4,
};

export const PROFILE_SETUP_STATUS_KEYS = {
  COMPLETED: "completed",
};

export const ACCOUNT_STATUS_KEYS = {
  ALL: "all",
  ACTIVE: "active",
  SUSPENDED: "suspended",
  SUSPENDED_DUE_TO_CREDENTIALS: "suspended-due-to-credentials",
  SUSPENDED_DUE_TO_PERFORMANCE: "suspended-due-to-performance",
};

export const ACCOUNT_STATUS_KEYS_TO_LABEL_MAP = {
  [ACCOUNT_STATUS_KEYS.ACTIVE]: "Active",
  [ACCOUNT_STATUS_KEYS.SUSPENDED]: "Suspended",
  [ACCOUNT_STATUS_KEYS.SUSPENDED_DUE_TO_PERFORMANCE]: "Performance",
  [ACCOUNT_STATUS_KEYS.SUSPENDED_DUE_TO_CREDENTIALS]: "Credentials",
};

export const PROMPT_KEYS = {
  LAB_ORDERING: "labOrderingPrompt",
  IMAGING_ORDERING: "imagingOrderingPrompt",
  DOCTOR_SUMMARY: "doctorSummaryPrompt",
  DOCTOR_RESPOND: "doctorRespondPrompt",
};

export const EncounterNoteTypes = {
  REGULAR_NOTE: "regular-note",
  PRESCRIPTION: "prescription",
  IMAGING_ORDER: "imaging-order",
  LAB_ORDER: "lab-order",
  WORK_EXCUSE_ORDER: "work-excuse-order",
  WEIGHT_LOSS_MEDICATION_ORDER: "weight-loss-medication-order",
  LAB_ORDER_RESULT: "lab-order-result",
  IMAGING_ORDER_RESULT: "imaging-order-result",
};

export const SystemTypes = {
  OWN: "own",
  RCOPIA: "rcopia",
};
