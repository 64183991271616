import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import { Box, CssBaseline } from "@mui/material";

import GlobalStyle from "../ui-shared/components/global-style";
import dashboardItems from "../ui-shared/sidebar/dashboardItems";
import Sidebar from "../ui-shared/sidebar/Sidebar";
import { useAuth } from "../contexts/FirebaseAuthContext";
import isEmpty from "lodash/isEmpty";
import { includes } from "lodash";
import Navbar from "../ui-shared/navbar/Navbar";
import useDrawerBreakpoints from "../ui-shared/hooks/use-drawer-breakpoints";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
`;

const AppContent = styled(Box)`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const PrivateLayout = ({ children }) => {
  const { user } = useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { webBreakpointKey, drawerWidth, isWeb } = useDrawerBreakpoints();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const filteredRoutes = useMemo(
    () =>
      dashboardItems.filter((item) => {
        return isEmpty(item.roles) ? true : includes(item.roles, user.role);
      }),
    [user.role],
  );

  return (
    <>
      <CssBaseline />
      <GlobalStyle />
      <Root>
        <Box
          sx={{
            width: { xs: 0, [webBreakpointKey]: drawerWidth },
            flexShrink: { sm: 0 },
          }}
        >
          {isWeb ? (
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              items={filteredRoutes}
              minSideBar
            />
          ) : (
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={filteredRoutes}
            />
          )}
        </Box>
        <AppContent sx={{ pl: { [webBreakpointKey]: `${drawerWidth}px` } }}>
          <Navbar onDrawerToggle={handleDrawerToggle} />
          <main>
            <Box>
              {children}
              <Outlet />
            </Box>
          </main>
        </AppContent>
      </Root>
    </>
  );
};

export default PrivateLayout;
