export const USER_CACHE_KEYS = {
  ME: "me",
  USER: "user",
  DOCTORS: "doctors",
  EXPIRY_CREDENTIALS: "expiry-credentials",
};

export const WAITING_ROOM_CACHE_KEYS = {
  LIST: "waiting-room-list",
};

export const RAPID_RESPONSE_CACHE_KEYS = {
  LIST: "rapid-response-list",
};
export const SYSTEM_SETTINGS_CACHE_KEYS = {
  GLOBAL: "global",
};

export const EARNING_CACHE_KEYS = {
  EARNINGS_SUMMARY: "earnings-summary",
  EARNINGS_DETAILS: "earnings-details",
};

export const PAYROLL_CACHE_KEYS = {
  PAYROLL_SUMMARY: "payroll-summary",
};

export const PRICE_CACHE_KEYS = {
  GET_ALL: "prices-get-all",
};

export const COMMISSION_CACHE_KEYS = {
  GET_ALL: "commission-get-all",
};

export const ENCOUNTER_NOTE_CACHE_KEYS = {
  LIST: "encounter-note",
};

export const DASHBOARD_CACHE_KEYS = {
  ADMIN_DASHBOARD_SUMMARY: "dashboard-admin-summary",
  DOCTOR_DASHBOARD_SUMMARY: "dashboard-doctor-summary",
  PATIENT_DASHBOARD_SUMMARY: "dashboard-patient-summary",
  PATIENT_SEEN_DOCTORS: "dashboard-patient-seen-doctors",
  DASHBOARD_ENCOUNTERS_SUMMARY: "dashboard-encounters-summary",
};

export const PATIENT_CACHE_KEYS = {
  PATIENT: "patient",
};

export const ENCOUNTER_CACHE_KEYS = {
  GET_ENCOUNTER: "encounter",
  GET_ENCOUNTER_CHAT: "encounter-chat",
  GET_WEIGHT_LOSS_FAXES: "encounter-weight-loss-faxes",
  SEARCH_PATIENT_ENCOUNTERS: "encounter-search-patient-encounters",
};

export const RCOPIA_CACHE_KEYS = {
  GET_ALLERGIES: "get-allergies",
  GET_MEDICATIONS: "get-medications",
  GET_NOTIFICATIONS_COUNT: "get-notifications-count",
};
