import { ReactComponent as DashboardIcon } from "./icons/grid.svg";
import { ReactComponent as MailIcon } from "./icons/mail.svg";
import { ReactComponent as PlusIcon } from "./icons/plus-circle.svg";
import { ReactComponent as HealthHistoryIcon } from "./icons/health-history.svg";
import { ReactComponent as ClockIcon } from "./icons/clock.svg";
import { ReactComponent as EarningsIcon } from "./icons/database.svg";

import { SYSTEM_ROLE_KEYS } from "../../domain/domain-constants/system";
import WaitingRoomBadge from "./badges/waiting-room-badge";

const navItems = [
  {
    href: "/dashboard",
    Icon: <DashboardIcon />,
    title: "Dashboard",
    roles: [
      SYSTEM_ROLE_KEYS.ADMIN,
      SYSTEM_ROLE_KEYS.SUPPORT,
      SYSTEM_ROLE_KEYS.DOCTOR,
      SYSTEM_ROLE_KEYS.PATIENT,
    ],
  },
  {
    href: "/waiting-room",
    title: "Waiting Room",
    roles: [
      SYSTEM_ROLE_KEYS.ADMIN,
      SYSTEM_ROLE_KEYS.SUPPORT,
      SYSTEM_ROLE_KEYS.DOCTOR,
    ],
    Icon: (
      <WaitingRoomBadge>
        <ClockIcon />
      </WaitingRoomBadge>
    ),
  },
  {
    href: "/new-encounter",
    Icon: <PlusIcon />,
    title: "New Encounter",
    roles: [SYSTEM_ROLE_KEYS.PATIENT],
  },
  {
    href: "/encounters",
    Icon: <MailIcon />,
    title: "Encounters",
    roles: [
      SYSTEM_ROLE_KEYS.ADMIN,
      SYSTEM_ROLE_KEYS.SUPPORT,
      SYSTEM_ROLE_KEYS.DOCTOR,
      SYSTEM_ROLE_KEYS.PATIENT,
    ],
  },
  {
    href: "/profile",
    Icon: <HealthHistoryIcon />,
    title: "Health History",
    roles: [SYSTEM_ROLE_KEYS.PATIENT],
  },
  {
    href: "/rapid-responses",
    Icon: <HealthHistoryIcon />,
    title: "Rapid Response",
    roles: [
      SYSTEM_ROLE_KEYS.ADMIN,
      SYSTEM_ROLE_KEYS.SUPPORT,
      SYSTEM_ROLE_KEYS.DOCTOR,
    ],
  },
  {
    href: "/earnings",
    Icon: <EarningsIcon />,
    title: "Earnings",
    roles: [
      SYSTEM_ROLE_KEYS.ADMIN,
      SYSTEM_ROLE_KEYS.SUPPORT,
      SYSTEM_ROLE_KEYS.DOCTOR,
    ],
  },
];

export default navItems;
